import {RootState} from "../store";
import {createSelector} from "@reduxjs/toolkit";
import {UserPermissions} from "../../types/UserTypes";

export interface appPermissions {
    [x: string]: boolean
}

export function isAuthenticated(store: RootState): boolean {
    return !!(store.user.authenticated)
}

const getUserPermissionAccess = (state: RootState): Array<UserPermissions> => state.user.user?.permissions.access || [];

export const getAppsPermissions = createSelector(
    [getUserPermissionAccess],
    (access) => {
        console.log(access)
        const apps: appPermissions = {
            'asset_register': false,
            'power_bi': false,
            'capital_budget': false,
            'unit_price': false,
            'valuation_model': false
        }

        access.forEach(perm => {
            if (apps.hasOwnProperty(perm.permission)) {
                apps[perm.permission] = true;
            }
        })

        return apps;
    }
)